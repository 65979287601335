.main-banner-container {
  display: block;
  width: 100%;
  margin-top: 15px;

  .slick-dots {
    bottom: 10px;
    right: 0;
    width: 100%;
    height: 36px;
    text-align: center;

    li {
      vertical-align: middle;
      width: 12px;
      height: 12px;
      margin-top: 10px;
      margin-left: 3px;
      margin-right: 3px;

      button {
        width: 12px;
        height: 12px;
        padding: 0;
        border-radius: 50%;
        // background-color: #b4ccb4;

        &::before {
          display: none;
        }
      }

      &.slick-active {
        button {
          background-color: #00b207;
        }
      }
    }
  }

  .banner-image {
    text-align: center;
    border-radius: 15px;
    overflow: hidden;
  }
}

.shop-categories-container {
  display: block;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
  .heading {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    h2 {
      display: inline-flex;
      font-size: 24px;
      color: #1a1a1a;
    }
    a {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      color: rgba(0, 178, 7, 1);
      svg {
        margin-left: 5px;
        width: 16px;
        height: 16px;
      }
    }
  }
  .categories {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    .special-category-box {
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      width: 280px;
      margin-right: 15px;
      .image {
        display: flex;
        width: 100%;
        position: relative;
        margin-bottom: 10px;

        a {
          display: flex;
          width: 100%;
          height: 160px;
          align-items: flex-end;
          justify-content: flex-end;
          padding: 0px;
          overflow: hidden;
          border-radius: 10px;
        }
      }
      .name {
        display: flex;
        width: 100%;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          font-weight: bold;
          color: rgba(114, 114, 114, 0.8);
          &:hover {
            color: rgba(0, 178, 7, 1);
          }
        }
      }
    }
    .category-box {
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      width: 160px;
      margin-right: 15px;
      &:last-child {
        margin-right: 0px;
      }
      .image {
        display: flex;
        width: 160px;
        height: 160px;

        margin-bottom: 10px;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 160px;
          height: 160px;
          padding: 0px;
          overflow: hidden;
          border-radius: 10px;
        }
      }
      .name {
        display: flex;
        width: 100%;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          font-weight: bold;
          color: rgba(114, 114, 114, 0.8);
          &:hover {
            color: rgba(0, 178, 7, 1);
          }
        }
      }
    }
  }
}

.service-container {
  display: flex;
  width: 100%;
  padding-bottom: 30px;
  .MuiContainer-root {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .services {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    border: 1px solid #f1f1f1;

    .service-box {
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        display: inline-flex;
        width: 60px;
        height: 60px;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 50%;
        background-color: #ffffff;
      }

      .info {
        display: flex;
        width: calc(100% - 60px);
        flex-wrap: wrap;

        .name {
          display: flex;
          width: 100%;
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
          color: #1a1a1a;
          margin-bottom: 5px;
        }

        .text {
          display: flex;
          width: 100%;
          font-size: 13px;
          line-height: 16px;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}

.customers-says-container {
  display: flex;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #117c40;

  .heading {
    display: flex;
    width: 100%;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    color: #ffffff;
    text-align: center;
    justify-content: center;
    margin-bottom: 40px;
  }

  .customers-container {
    padding-left: 30px;
    padding-right: 30px;

    .customers-says {
      width: calc(100% - 30px) !important;
      margin-left: 15px;
      margin-right: 15px;
      border-radius: 10px;
      background-color: #ffffff;

      .customers-info {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;

        .ratting {
          display: flex;
          width: 100%;

          svg {
            color: #ff8a00;

            &.gray {
              color: #cccccc;
            }
          }
        }

        .name {
          display: flex;
          width: 100%;
          font-size: 16px;
          line-height: 22px;
          font-weight: 600;
          color: #213028;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .text {
          display: -webkit-box;
          width: 100%;
          font-size: 15px;
          line-height: 24px;
          font-weight: 400;
          color: #406b41;
          overflow: hidden;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }

        .customer-bg {
          display: flex;
          width: 100%;
          text-align: end;
          justify-content: flex-end;
        }
      }

      .customers-person {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        border-top: 1px solid #1f915180;

        .icon {
          display: inline-flex;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 15px;
        }

        .info {
          display: inline-flex;
          flex-wrap: wrap;
          width: calc(100% - 75px);

          .name {
            display: flex;
            width: 100%;
            color: #213028;
            font-size: 18px;
            line-height: 22px;
            font-weight: 600;
          }

          .customer {
            display: flex;
            width: 100%;
            color: #1f9151;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
          }
        }
      }
    }

    .slick-prev,
    .slick-next {
      width: 32px;
      height: 32px;

      &::before {
        display: none;
      }
    }

    .slick-next {
      right: -30px;
      border-radius: 50%;
      border: 1px solid #c5c5c5;
      background-color: #ffffff;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url("../../img/customer-right-arrow.png");

      &:hover,
      &:focus {
        background-color: #ffffff;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url("../../img/customer-right-arrow.png");
      }
    }

    .slick-prev {
      left: -30px;
      border-radius: 50%;
      border: 1px solid #c5c5c5;
      background-color: #ffffff;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url("../../img/customer-left-arrow.png");

      &:hover,
      &:focus {
        background-color: #ffffff;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url("../../img/customer-left-arrow.png");
      }
    }
  }
}

.top-selling-categories-container {
  display: flex;
  width: 100%;

  .selling-categories {
    display: block;
    width: 100%;
    margin-bottom: 30px;
  }

  .heading {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    h2 {
      display: inline-flex;
      font-size: 24px;
      color: #1a1a1a;
    }
    a {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      color: rgba(0, 178, 7, 1);
      svg {
        margin-left: 5px;
        width: 16px;
        height: 16px;
      }
    }
  }

  .slick-slider {
    .slick-track {
      margin: 0;
    }
  }

  .slick-prev,
  .slick-next {
    width: 36px;
    height: 36px;
    top: calc(50% - 16px);
    z-index: 1;
    transform: translate(0, 0%);
    border-radius: 50%;
    border: none;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    &:focus,
    &:hover {
      background-color: #ffffff;
    }
    &::before {
      width: 36px;
      height: 36px;
      opacity: 1;
      display: block;
    }
  }

  .slick-next {
    right: -18px;
    &::before {
      font-size: 0px;
      background-image: url("../../img/slick-right-arrow.png");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .slick-prev {
    left: -18px;
    &::before {
      font-size: 0px;
      background-image: url("../../img/slick-left-arrow.png");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .slick-dots {
    display: none !important;
  }

  .product-box {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 15px) !important;
    margin-left: 0px;
    margin-right: 15px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    position: relative;
    &.hide {
      opacity: 0.6;
    }

    .sale {
      position: absolute;
      left: 0;
      top: 0;
      width: 90px;
      height: 32px;
      font-size: 14px;
      line-height: 32px;
      font-weight: 400;
      color: #ffffff;
      display: inline-flex;
      justify-content: center;
      text-align: center;
      background-color: #1f9151;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .icon {
      cursor: pointer;
      background-color: #fff;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 24px;
      height: 24px;
      font-size: 13px;
      color: #141b3499;
      font-weight: 400;
      line-height: 24px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      border: 1px solid #e5e5e5;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 200px;
      margin: 0px;
      cursor: pointer;

      img {
        max-height: 150px;
      }
    }

    .name {
      display: flex;
      width: 100%;
      min-height: 40px;
      padding-bottom: 5px;

      a {
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #000000;

        &:hover {
          color: #1f9151;
        }
      }
    }

    .select {
      display: flex;
      width: 100%;
      font-size: 13px;
      line-height: 18px;
      color: #958e8e;
      margin-bottom: 10px;
      select {
        width: 100%;
        font-size: 13px;
        line-height: 18px;
        border: 1px solid #cacaca;
        border-radius: 3px;
        padding-left: 3px;
        padding-right: 3px;
        padding-top: 2px;
        padding-bottom: 2px;
        cursor: pointer;
        option {
          cursor: pointer;
        }
      }
    }

    .price-cart {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .price {
        width: 80px;
        display: inline-flex;
        flex-wrap: wrap;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #1a1a1a;

        strong {
          display: flex;
          width: 100%;
          align-items: center;
          font-size: 14px;
          color: #2d2d2d;
          svg {
            max-width: 14px;
            height: 14px;
          }
        }

        span {
          display: flex;
          align-items: center;
          width: 100%;
          color: #808080;
          font-size: 13px;
          font-weight: 400;
          text-decoration: line-through;
          svg {
            max-width: 12px;
            height: 12px;
          }
        }
      }
      .add-cart {
        display: inline-flex;
        width: 64px;

        button {
          border: 1px solid #1f9151;
          width: calc(100% - 2px);
          padding: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #1f9151;
          text-transform: capitalize;
          height: 36px;
          background-color: rgba(0, 178, 7, 0.1);
          &.Mui-disabled {
            color: rgba(0, 0, 0, 0.26) !important;
            border: 1px solid rgba(0, 0, 0, 0.26) !important;
          }
        }
      }
    }

    .number-input-container {
      display: inline-flex;
      width: 64px;

      .symbol {
        width: 33.33%;
        border: 1px solid #1f9151;
        background-color: #1f9151;
        height: 36px;
        cursor: pointer;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border-radius: 4px 0px 0px 4px;

        &:last-child {
          border-radius: 0px 4px 4px 0px;
        }
      }

      .Number {
        width: 33.33%;
        height: 36px;
        border: 1px solid #1f9151;
        background-color: #1f9151;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1150px) {
  .shop-categories-container {
    padding-bottom: 15px;
    .categories {
      flex-wrap: wrap;
      .special-category-box {
        margin-bottom: 15px;
      }
      .category-box {
        margin-bottom: 15px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .main-banner-container {
    .banner-image {
      height: 180px;
      a {
        display: block;
        height: 100%;
      }
      img {
        height: 180px;
      }
    }
  }
  .shop-categories-container {
    padding-bottom: 10px;
    .heading {
      margin-bottom: 10px;
      h2 {
        font-size: 18px;
      }
      a {
        display: none;
      }
    }
    .categories {
      flex-wrap: wrap;
      align-items: flex-start;
      .special-category-box {
        margin-right: 0;
        margin-bottom: 15px;
        width: 100%;
        .image {
          margin-bottom: 0px;
          a {
            height: auto;
            justify-content: flex-start;
            img {
              width: 100%;
              // max-height: 160px;
            }
          }
        }
        .name {
          display: none;
        }
      }
      .category-box {
        width: 30%;
        align-items: flex-start;
        margin-bottom: 15px;
        &:nth-child(4n + 4) {
          margin-right: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        .image {
          width: 100%;
          height: 100px;
          a {
            width: 100%;
            height: 100px;
          }
        }
        .name {
          align-items: flex-start;
          height: 32px;
          a {
            text-align: center;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      .view-all-link {
        width: 30%;
        height: 100px;
        a {
          width: 100%;
          height: 100%;
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          font-weight: 500;
          background-color: rgba(0, 178, 7, 0.1);
        }
      }
    }
  }
  .top-selling-categories-container {
    .heading {
      h2 {
        font-size: 18px;
      }
    }
    .slick-prev {
      left: -10px;
    }
    .slick-next {
      right: -10px;
    }
  }
  .service-container {
    display: none;
  }
  .customers-says-container {
    padding-top: 20px;
    padding-bottom: 20px;
    .heading {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
}

.hyderabad_tag_line{
  margin-bottom: 15px;
   background: #e6f8e7;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  font-style: italic;
  color: rgba(114, 114, 114, 0.8);
  padding: 8px;
  text-align: center;
}
@media screen and (max-width:600px) {
  .hyderabad_tag_line{
    margin-bottom: 15px;
    margin-top: 0px;
    background: #e6f8e7;
    border-radius: 8px;
    font-size: 0.8rem;
    font-weight: 600;
    font-style: italic;
    color: rgba(114, 114, 114, 0.8);
    padding: 8px;
    text-align: center;
  }


  .top-selling-categories-container { 
    padding-bottom:60px ;
     }
}


