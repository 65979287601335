.header {
  display: flex;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #005f41;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;

  .logo {
    display: inline-flex;
    max-width: 180px;
    img {
      display: block;
    }
  }

  .deliver-box {
    display: inline-flex;
    align-items: center;
    max-width: 200px;
    flex-wrap: wrap;
    margin-left: 20px;
    cursor: pointer;

    strong {
      display: flex;
      width: 100%;
      font-size: 14px;
      line-height: 10px;
      font-weight: 600;
      color: #ffffff;
      padding-bottom: 8px;
    }

    span {
      position: relative;
      display: block;
      width: 100%;
      color: #ffffff;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 15px;
      svg {
        position: absolute;
        right: 0;
        top: -3px;
      }
    }
  }

  .login {
    display: inline-flex;
    margin-right: 30px;
    color: #ffffff;
    cursor: pointer;
  }

  .card {
    display: inline-flex;
    position: relative;
    min-width: 70px;
    align-items: center;
    a {
      display: flex;
      align-items: center;
    }

    span {
      font-size: 16px;
      line-height: 18px;
      font-weight: 600;
      color: #ffffff;
      padding-left: 10px;
    }

    p {
      position: absolute;
      left: 8px;
      top: -8px;
      width: 16px;
      height: 16px;
      font-size: 11px;
      line-height: 16px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      border-radius: 50%;
      background-color: #ea5044;
    }
  }

  .search-box {
    display: inline-flex;
    width: 100%;
    

    .back-button {
      display: none;
    }

    .search-container {
      width: 100%;
      position: relative;
      z-index: 100;
    
      .search {
        width: 100%;
    
        input {
          padding-top: 10px;
          padding-bottom: 8px;
          transition: opacity 1.5s ease-in-out;
        }
      }
    
      // Media query for smaller screens
      @media (max-width: 768px) {
        position: static; // Make position static on small screens
        
      }
    
    
      .MuiInputBase-root {
        background-color: #ffffff;
        border-radius: 10px;
        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: #ffffff !important;
          }
        }
      }
    }
    .search-results {
      display: none;
      flex-wrap: wrap;
      width: 100%;
      position: absolute;
      top: 55px;
      left: 0;
      overflow: hidden;
      overflow-y: auto;
      min-height: 110px;
      max-height: 350px;
      padding-left: 8px;
      padding-right: 12px;
      border-radius: 3px;
      background-color: #ffffff;
      border: 1px solid #d2cfcf;
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
      .result-product {
        display: flex;
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #d2cfcf;
        &:last-child {
          border-bottom: none;
        }
        .image {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 80px;
          cursor: pointer;

          img {
            max-height: 80px;
          }
        }

        .name {
          display: flex;
          width: 100%;

          a {
            font-size: 13px;
            line-height: 18px;
            color: #535554;
            font-weight: 400;

            &:hover {
              color: #1f9151;
            }
          }
        }

        .price-ratting {
          display: flex;
          width: 100%;
          justify-content: space-between;

          .price {
            display: inline-flex;
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #1a1a1a;
            align-items: center;

            img {
              max-width: 8px;
              height: 12px;
              margin-right: 2px;
            }

            span {
              margin-left: 7px;
              color: #adadad;
              font-weight: 400;
              text-decoration: line-through;
            }
          }

          .ratting {
            display: inline-flex;
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #1a1a1a;
            align-items: center;

            svg {
              width: 18px;
              margin-right: 3px;
              color: #ff8a00;
            }
          }
        }

        .add-cart {
          display: flex;
          width: 100%;

          button {
            border: 1px solid #1f9151;
            padding: 1px;
            width: 100%;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            color: #1f9151;
            text-transform: capitalize;
            height: 36px;
            &.Mui-disabled {
              color: rgba(0, 0, 0, 0.26) !important;
              border: 1px solid rgba(0, 0, 0, 0.12) !important;
            }
          }
        }

        .number-input-container {
          display: flex;
          width: 100%;

          .symbol {
            width: 33.33%;
            border: 1px solid #1f9151;
            background-color: #1f9151;
            height: 36px;
            cursor: pointer;
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            border-radius: 4px 0px 0px 4px;

            &:last-child {
              border-radius: 0px 4px 4px 0px;
            }
          }

          .Number {
            width: 33.33%;
            height: 36px;
            border: 1px solid #1f9151;
            background-color: #ffffff;
            font-size: 16px;
            line-height: 24px;
            color: #1f9151;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
          }
        }
        .select {
          display: flex;
          width: 50%;
          font-size: 13px;
          line-height: 18px;
          color: #958e8e;
          margin-top: 10px;
          select {
            width: 100%;
            font-size: 13px;
            line-height: 18px;
            border: 1px solid #cacaca;
            border-radius: 3px;
            padding-left: 3px;
            padding-right: 3px;
            padding-top: 2px;
            padding-bottom: 2px;
            cursor: pointer;
            option {
              cursor: pointer;
            }
          }
        }
      }
      &.active {
        display: flex;
      }
      .search-loader {
        display: flex;
        width: 100%;
        max-height: 110px;
        align-items: center;
        justify-content: center;
      }
    }

    .search-results-bg {
      display: none;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      &.active {
        display: block;
      }
    }
  }
}

.categories-container {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
  display: none;
  width: 100%;
  position: fixed;
  left: 0;
  top: 70px;
  z-index: 10;
  &.category {
    display: block;
  }
  ul {
    display: flex;
    width: 100%;
    padding: 0;
    li {
      display: inline-flex;
      width: auto;
      list-style: none;
      padding-right: 15px;
      a {
        display: block;
        padding: 10px 15px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        border-radius: 25px;
        color: rgba(0, 0, 0, 0.4);
        background-color: #f7fbff;
        &:hover {
          color: #00b207;
          background-color: rgba(0, 178, 7, 0.1);
        }
        &.active {
          color: #00b207;
          background-color: rgba(0, 178, 7, 0.1);
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .header {
    .deliver-box {
      max-width: 150px;
    }
    .login {
      display: inline-block;
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .categories-container {
    ul {
      li {
        padding-right: 10px;
        a {
          font-size: 12px;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .header {
    .logo {
      display: none;
    }
    .deliver-box {
      display: none;
    }
    .login {
      display: none;
    }
    .card {
      min-width: auto;
      span {
        display: none;
      }
    }
    .profile-icon {
      display: inline-flex;
      color: #ffffff;
      margin-left: 15px;
    }
    .search-box {
      .back-button {
        color: #ffffff;
        display: inline-flex;
        align-items: center;
        width: 40px;
        height: 40px;
        &.none {
          display: none;
        }
      }
    }
  }
  .mobile-deliver-box {
    cursor: pointer;
    position: fixed;
    z-index: 10;
    top: 70px;
    left: 0;
    align-items: center;
    justify-content: space-between;
    display: flex;
    font-weight: 500;
    line-height: 18px;
    font-size: 13px;
    color: #ffffff;
    padding: 10px 15px;
    width: 100%;
    background-color: #1f9151;
  }
  .categories-container {
    &.category {
      display: none;
    }
  }
}
.profile_modal_par{
  position: relative;
}
.profile-modal {
  position: absolute;
  top: 50px;
  right: 0;
  left: 0;
  z-index: 10;
  width: 180px;
  padding-bottom: 10px;
  background-color: #ffffff;
  box-shadow: 0px 12px 16px 0px #10182814;

  ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      width: 100%;
      align-items: center;

      &:first-child {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #e2e4e9;
      }

      a {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 5px 15px;
        color: #344054;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;

        svg {
          display: inline-flex;
          margin-right: 5px;
        }

        &:hover {
          color: #1f9151;
          background-color: #1f915133;
        }
      }
    }
  }

  // Add this block for the image background
  img {
    background-color: #1f9151; /* Green background for img */
    padding: 5px; /* Optional: add some padding if needed */
    border-radius: 5px; /* Optional: round the corners of the image */
  }
}

.profile-modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.anchortagheader{
  font-size: 16px;
  color: white;
  text-decoration: underline;  /* Underlines the text */

}

.smalltext{
  font-size: 12px;
  color: black;
}