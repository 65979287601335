.details-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;

  .product-images {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .big-image {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      height: 450px;
      border-radius: 10px;
      position: relative;
      border: 1px solid #b9b9b9;
      z-index: 9;
      padding: 15px;

      img {
        max-height: 420px;
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        z-index: 10;
        background-color: #fff;
        border-radius: 3px;
        border: 1px solid #e5e5e5;

        svg {
          width: 28px;
          height: 28px;
          color: #141b34;
        }
      }
    }

    .thumbnail-images {
      display: flex;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
      align-items: center;
      justify-content: center;

      ul {
        display: flex;
        width: 100%;
        padding: 0;
        list-style: none;
        margin: 0;
        flex-wrap: wrap;

        li {
          margin-left: 10px;
          margin-right: 10px;
          display: inline-flex;
          position: relative;
          margin: 10px;

          &.active {
            a {
              border-color: #1f9151;
            }

            &::after {
              content: "";
              position: absolute;
              left: 38%;
              bottom: -10px;
              width: 20px;
              border-radius: 3px;
              border: 2px solid #1f9151;
            }
          }

          a {
            display: flex;
            width: 90px;
            height: 90px;
            overflow: hidden;
            border-radius: 5px;
            align-items: center;
            justify-content: center;
            border: 1px solid #b9b9b9;
          }
        }
      }
    }
  }

  .product-description {
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    h4 {
      font-weight: 500;
      color: #000000;
      margin-bottom: 0px;
    }
    p {
      display: block;
      margin-top: 5px;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .product-info {
    display: flex;
    width: 100%;
    padding-left: 0px;
    flex-wrap: wrap;

    .breadcrum {
      display: flex;
      width: 100%;
      margin-bottom: 15px;
      ul {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          display: inline-flex;
          color: #6e758e;
          span {
            display: inline-flex;
            margin-left: 5px;
            margin-right: 5px;
          }
          a {
            color: #6e758e;
            &:hover {
              color: #1f9151;
            }
          }
          strong {
            font-weight: 400;
            color: #1f9151;
          }
        }
      }
    }

    .product-name {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-size: 24px;
      line-height: 26px;
      font-weight: 600;
      color: #2a3a31;
      margin-bottom: 5px;
      span {
        display: inline-flex;
      }
      .share-icon {
        display: inline-flex;
        position: relative;
        svg {
          cursor: pointer;
        }
        ul {
          position: absolute;
          top: 30px;
          right: 0;
          width: 150px;
          list-style: none;
          padding: 10px 15px;
          margin: 0;
          border-radius: 10px;
          border: 1px solid #b9b9b9;
          li {
            display: flex;
            width: 100%;
            padding-bottom: 5px;
            button {
              display: flex;
              width: 100%;
              justify-content: flex-start;
              align-items: center;
              font-size: 14px !important;
              svg {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    .product-price {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 10px;

      .mrp {
        display: flex;
        width: auto;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #adadad;

        img {
          margin-top: 0px;
          max-height: 16px;
        }

        span {
          text-decoration: line-through;
        }
      }

      .price {
        display: flex;
        width: auto;
        margin-right: 10px;
        align-items: center;
        font-weight: 500;
        font-size: 20px;
        color: #1a1a1a;

        img {
          margin-right: 3px;
        }

        span {
          display: inline-flex;
          margin-left: 10px;
          font-size: 16px;
          color: #adadad;
        }
      }
    }

    .product-save {
      display: flex;
      width: 100%;
      font-size: 13px;
      line-height: 18px;
      font-weight: 400;
      color: #000000;
      margin-top: 10px;

      span {
        display: inline-flex;
        background-color: #1f91511a;
        padding-left: 5px;
        padding-right: 5px;
        color: #1f9151;
        margin-left: 5px;
      }
    }

    .select {
      margin-top: 10px;
      display: flex;
      width: 150px;
      font-size: 12px;
      line-height: 28px;
      color: #adadad;
      > div {
        > div {
          border: 1px solid #a8a8a833;
          border-radius: 3px;
          padding-left: 10px;

          &::before {
            display: none;
          }

          &::after {
            display: none;
          }

          select {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }

    .product-cart-buttons {
      display: flex;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #e6e6e6;

      .number-input-container {
        display: flex;
        width: 100%;
        .disableClick {
          pointer-events: "none";
          width: 33.33%;
          border: 1px solid #bfcac4;
          background-color: #d3e1d9;
          height: 40px;
          cursor: pointer;
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          border-radius: 4px 0px 0px 4px;

          &:last-child {
            border-radius: 0px 4px 4px 0px;
          }
        }
        .symbol {
          width: 33.33%;
          border: 1px solid #1f9151;
          background-color: #1f9151;
          height: 40px;
          cursor: pointer;
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          border-radius: 4px 0px 0px 4px;

          &:last-child {
            border-radius: 0px 4px 4px 0px;
          }
        }

        .Number {
          width: 33.33%;
          height: 40px;
          border: 1px solid #1f9151;
          background-color: #1f9151;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
        }
      }

      .add-cart-btn {
        width: 100%;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        box-shadow: none;
        border-radius: 5px;
        text-transform: capitalize;
        background-color: #1f9151;
        border: 1px solid #1f9151;
        &.Mui-disabled {
          border-color: rgba(0, 0, 0, 0.26);
          background-color: #ffffff;
          color: rgba(0, 0, 0, 0.26) !important;
        }
      }
    }
  }

  .choose-promode {
    display: block;
    width: 100%;
    padding-top: 15px;
    h4 {
      font-weight: 500;
      color: #000000;
      margin-bottom: 10px;
      span {
        font-weight: 600;
        color: #1f9151;
      }
    }
    ul {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        display: inline-flex;
        width: 50%;
        align-items: center;
        .image {
          display: inline-flex;
          width: 60px;
          height: 60px;
          align-items: center;
          justify-content: center;
          text-align: center;
          border-radius: 50%;
          background-color: #ffffff;
        }
        .contents {
          display: flex;
          width: calc(100% - 60px);
          flex-wrap: wrap;
          strong {
            display: flex;
            width: 100%;
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            color: #1a1a1a;
            margin-bottom: 5px;
          }
          p {
            display: flex;
            width: 100%;
            font-size: 13px;
            line-height: 16px;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }
}

.top-selling-categories-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .selling-categories {
    display: block;
    width: 100%;
    margin-bottom: 30px;
  }

  .heading {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    h2 {
      display: inline-flex;
      font-size: 24px;
      color: #1a1a1a;
    }
    a {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      color: rgba(0, 178, 7, 1);
      svg {
        margin-left: 5px;
        width: 16px;
        height: 16px;
      }
    }
  }

  .slick-slider {
    .slick-track {
      margin: 0;
    }
  }

  .slick-prev,
  .slick-next {
    width: 36px;
    height: 36px;
    top: calc(50% - 16px);
    z-index: 1;
    transform: translate(0, 0%);
    border-radius: 50%;
    border: none;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    &:focus,
    &:hover {
      background-color: #ffffff;
    }
    &::before {
      width: 36px;
      height: 36px;
      opacity: 1;
      display: block;
    }
  }

  .slick-next {
    right: -18px;
    &::before {
      font-size: 0px;
      background-image: url("../../img/slick-right-arrow.png");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .slick-prev {
    left: -18px;
    &::before {
      font-size: 0px;
      background-image: url("../../img/slick-left-arrow.png");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .slick-dots {
    display: none !important;
  }

  .product-box {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 15px) !important;
    margin-left: 0px;
    margin-right: 15px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    position: relative;

    &.hide{
      opacity: 0.6;
    }

    .sale {
      position: absolute;
      left: 0;
      top: 0;
      width: 90px;
      height: 32px;
      font-size: 14px;
      line-height: 32px;
      font-weight: 400;
      color: #ffffff;
      display: inline-flex;
      justify-content: center;
      text-align: center;
      background-color: #1f9151;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .icon {
      cursor: pointer;
      background-color: #fff;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 24px;
      height: 24px;
      font-size: 13px;
      color: #141b3499;
      font-weight: 400;
      line-height: 24px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      border: 1px solid #e5e5e5;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 200px;
      margin: 0px;
      cursor: pointer;

      img {
        max-height: 150px;
      }
    }

    .name {
      display: flex;
      width: 100%;
      min-height: 40px;
      padding-bottom: 5px;

      a {
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #000000;

        &:hover {
          color: #1f9151;
        }
      }
    }

    .select {
      display: flex;
      width: 100%;
      font-size: 13px;
      line-height: 18px;
      color: #958e8e;
      margin-bottom: 10px;
      select {
        width: 100%;
        font-size: 13px;
        line-height: 18px;
        border: 1px solid #cacaca;
        border-radius: 3px;
        padding-left: 3px;
        padding-right: 3px;
        padding-top: 2px;
        padding-bottom: 2px;
        cursor: pointer;
        option {
          cursor: pointer;
        }
      }
    }

    .price-cart {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .price {
        width: 80px;
        display: inline-flex;
        flex-wrap: wrap;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #1a1a1a;

        strong {
          display: flex;
          width: 100%;
          align-items: center;
          font-size: 14px;
          color: #2d2d2d;
          svg {
            max-width: 14px;
            height: 14px;
          }
        }

        span {
          display: flex;
          align-items: center;
          width: 100%;
          color: #808080;
          font-size: 13px;
          font-weight: 400;
          text-decoration: line-through;
          svg {
            max-width: 12px;
            height: 12px;
          }
        }
      }
      .add-cart {
        display: inline-flex;
        width: 64px;

        button {
          border: 1px solid #1f9151;
          width: calc(100% - 2px);
          padding: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #1f9151;
          text-transform: capitalize;
          height: 36px;
          background-color: rgba(0, 178, 7, 0.1);
          &.Mui-disabled {
            color: rgba(0, 0, 0, 0.26) !important;
            border: 1px solid rgba(0, 0, 0, 0.26) !important;
          }
        }
      }
    }

    .number-input-container {
      display: inline-flex;
      width: 64px;

      .symbol {
        width: 33.33%;
        border: 1px solid #1f9151;
        background-color: #1f9151;
        height: 36px;
        cursor: pointer;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border-radius: 4px 0px 0px 4px;

        &:last-child {
          border-radius: 0px 4px 4px 0px;
        }
      }

      .Number {
        width: 33.33%;
        height: 36px;
        border: 1px solid #1f9151;
        background-color: #1f9151;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .details-container {
    .product-info {
      .breadcrum {
        display: none;
      }
      .choose-promode {
        ul {
          li {
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .details-container {
    .product-images {
      margin-bottom: 10px;
      .big-image {
        height: 250px;
        img {
          max-height: 220px;
        }
      }
    }
    .product-description {
      margin-top: 0;
      margin-bottom: 10px;
    }
    .choose-promode {
      ul {
        li {
          width: 48%;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          padding: 15px;
          margin-left: 1%;
          margin-right: 1%;
          margin-bottom: 10px;
          border-radius: 5px;
          background-color: rgba(0, 178, 7, 0.1);
          .image {
            width: 48px;
            height: 48px;
            padding: 5px;
            margin-bottom: 10px;
          }
          .contents {
            width: 100%;
            justify-content: center;
            strong {
              justify-content: center;
              text-align: center;
            }
            p {
              min-height: 32px;
              font-size: 12px;
              justify-content: center;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .top-selling-categories-container {
    .heading {
      h2 {
        font-size: 18px;
      }
    }
    .slick-prev {
      left: -10px;
    }
    .slick-next {
      right: -10px;
    }
  }
}
