.my-order-container {
  margin: 36px 0px 77px 0px;
  .invoice-download:hover {
    color: rgba(0, 0, 0, 0.87); /* MUI default hover color */
  }

  .my-order-list {
    h2 {
      font-weight: 600;
      color: #2a3a31;
    }

    .order-status {
      padding: 8px 24px;
      font-size: 14px;
      line-height: 20px;
      border-radius: 4px;
      margin-left: 19px;
      background-color: #1f91511a;
      color: #1f9151;
    }

    .last-month-order {
      font-size: 14px;
      line-height: 14px;
      color: #2a3a31;
      margin-top: 8px;
      font-weight: 500;
      margin-bottom: 32px;

      strong {
        color: #000000;
        font-weight: 700;
        margin-left: 10px;
        margin-right: 5px;
      }
    }

    .order-status-collapsed {
      padding: 6px;
      min-height: 104px;
      border: 1px solid #bfbfbf;
      border-radius: 4px;
      margin-top: 28px;
      width: 100%;
      overflow: hidden;
      overflow-x: auto;

      .inner-order {
        max-width: 1125px;
        display: block;
        width: 1125px;
      }

      .date-time-container {
        padding: 24px 27px;
        display: flex;
        align-items: center;
        font-size: 17px;
        line-height: 20px;
        font-weight: 600;
        height: 90px;
        border-radius: 4px;

        svg {
          margin-right: 8px;
        }

        &.order-progress {
          color: #1f9151;
          background-color: #1f91511a;
        }

        &.order-delivered {
          color: #777777;
        }
      }

      .order-status-bar {
        .custom-step-icon {
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            border-radius: 50px;
            border: 3px solid #1f9151;
            opacity: 0.4;
            &.order-placed {
              background-position: center center;
              background-repeat: no-repeat;
              background-image: url(../../../img/order-placed.png);
            }
            &.in-process {
              background-position: center center;
              background-repeat: no-repeat;
              background-image: url(../../../img/in-process.png);
            }
            &.packed {
              background-position: center center;
              background-repeat: no-repeat;
              background-image: url(../../../img/packed.png);
            }
            &.on-the-way {
              background-position: center center;
              background-repeat: no-repeat;
              background-image: url(../../../img/on-the-way.png);
            }
            &.delivered {
              background-position: center center;
              background-repeat: no-repeat;
              background-image: url(../../../img/delivered.png);
            }
            &.completed {
              background-color: #1f9151;
              svg {
                width: 32px;
                height: 32px;
                color: #ffffff;
              }
            }
            &.active {
              border-style: dashed;
              opacity: 1;
            }
          }
        }

        .MuiStepLabel-alternativeLabel {
          margin-top: 0px;
          font-size: 11px;
          line-height: 20px;
          color: #2a3a31;
          font-weight: 400;
        }

        .MuiStepConnector-root {
          top: 21px;

          .MuiStepConnector-line {
            border-color: #1f9151;
            border-width: 1.5px;
          }
        }
      }

      .order-id-container {
        padding: 10px 18px;
        border-radius: 6px;
        height: 64px;
        width: max-content;
        text-overflow: clip;
        
        .title {
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
        }

        .number {
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
        }

        svg {
          margin-left: 10px;
          font-size: 14px;
          cursor: pointer;
        }

        &.order-progress {
          background: #1f91511a;
          background-color: #1f91511a;

          .title {
            color: #2a3a31;
          }

          .number {
            color: #4b5b52;
          }

          svg {
            color: #1f9151;
          }
        }

        &.order-delivered {
          color: #777777;
          border: 1px solid #a8a8a8;

          .title {
            color: #2a3a31;
          }

          .number {
            color: #4b5b52;
          }

          svg {
            color: #a8a8a8;
          }
        }
      }

      .collapsed-arrow {
        button {
          border: 1.5px solid #6e6e6e;
          height: 20px;
          width: 20px;

          svg {
            color: #6e6e6e;
          }
        }
      }

      .order-status-container {
        padding: 8px 0px;
        font-size: 14px;
        line-height: 20px;
        border-radius: 4px;
        display: flex;
        justify-content: center;

        &.delivered {
          background-color: #1f91511a;
          color: #1f9151;
        }

        &.cancelled {
          background-color: #d306061a;
          color: #d30606;
        }
      }
    }

    .order-details-container {
      padding: 18px 24px;
      border: 1px solid #bfbfbf;
      margin-top: 12px;
      border-radius: 4px;

      .order-place-time {
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        font-weight: 400;
        margin-bottom: 15px;
      }

      .delivery-address {
        h3 {
          font-weight: 600;
          color: #000000;
          padding-bottom: 6px;
        }

        .info {
          font-size: 13px;
          line-height: 18px;
          color: #6f6f6f;
          font-weight: 400;
          margin-top: 6px;

          .payment-status {
            font-weight: 700;
            color: #1f9151;
          }

          .payment-type {
            font-weight: 700;
            color: #000000;
          }
        }
      }

      .more-order-details {
        margin-top: 30px;

        .text {
          font-size: 16px;
          line-height: 20px;
          color: #707070;
          font-weight: 400;
          margin-bottom: 20px;
        }

        Button {
          display: block;
          border: 1px solid #bfbfbf;
          height: 72px;
          border-radius: 8px;
          text-transform: capitalize;
          width: 220px;

          .title {
            color: #2a3a31;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
          }

          .sub-title {
            color: #6f6f6f;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            margin-top: 5px;
          }
        }
      }

      .order-summary-container {
        padding: 15px;
        background-color: #1f91511a;
        overflow: hidden;
        position: relative;
        max-width: 300px;
        border-radius: 5px;

        .title {
          font-weight: 500;
          margin-bottom:  10px;
        }

        .summary-title {
          font-size: 13px;
          line-height: 18px;
          margin-bottom: 5px;
        }

        .order-amount {
          font-size: 12px;
          line-height: 20px;
          color: #000000;
          font-weight: 600;
          margin-bottom: 7px;
        }

        .saving-amount {
          font-size: 12px;
          line-height: 20px;
          color: #1f9151;
          font-weight: 400;
          // margin-top: 7px;
        }

        .top-cricle {
          top: -20px;
          position: absolute;

          &.circle-1 {
            left: 36px;
          }

          &.circle-2 {
            left: 96px;
          }

          &.circle-3 {
            left: 165px;
          }

          &.circle-4 {
            left: 235px;
          }

          .cricle {
            width: 38px;
            height: 38px;
            border-radius: 100%;
            background-color: #ffffff;
          }
        }

        .bottom-cricle {
          bottom: -20px;
          position: absolute;

          &.circle-1 {
            left: 36px;
          }

          &.circle-2 {
            left: 96px;
          }

          &.circle-3 {
            left: 165px;
          }

          &.circle-4 {
            left: 235px;
          }

          .cricle {
            width: 38px;
            height: 38px;
            border-radius: 100%;
            background-color: #ffffff;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .more-order-details {
    Button {
      width: 100% !important;
    }
  }
}
